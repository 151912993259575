var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isValid
    ? _c(
        "span",
        {
          class: _vm.type === "difference" ? _vm.getPercentClass() : "",
          attrs: {
            title:
              _vm.type === "difference"
                ? "Difference"
                : "Percent of " + _vm.type
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.type === "difference"
                  ? _vm.calculatePercentDifference()
                  : _vm.calculatePercentOfIncome()
              ) +
              " "
          ),
          _c("b-icon-info-circle-fill", {
            staticStyle: { "vertical-align": "text-top" },
            attrs: { variant: "info", "font-scale": "0.75" }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }