var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-row",
                {
                  staticClass: "pr-0 pr-md-5",
                  attrs: { "align-v": "center" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.collapse = !_vm.collapse
                    }
                  }
                },
                [
                  _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.name))]),
                    _vm.accounts && _vm.accounts.transactions
                      ? _c(
                          "span",
                          { staticClass: "pl-2" },
                          [
                            _vm.collapse
                              ? _c("b-icon-caret-up-fill")
                              : _c("b-icon-caret-down-fill")
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right",
                      class: _vm.isComparing ? "pr-md-0" : "",
                      attrs: {
                        offset: "0",
                        "offset-md": !_vm.isComparing ? 3 : 0,
                        cols: "12",
                        md: "3"
                      }
                    },
                    [
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "", "align-v": "center" } },
                        [
                          _c("b-col", { staticClass: "pl-1" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm._f("total")(_vm.accounts)
                                  )
                                )
                              )
                            ])
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "small text-center" },
                            [
                              _vm.hasIncome
                                ? _c("PercentDisplay", {
                                    staticClass: "pl-3",
                                    attrs: {
                                      type: "income",
                                      "new-amount": Math.abs(
                                        _vm.accounts.total
                                      ),
                                      "old-amount": _vm.income
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isComparing
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { cols: "12", md: "3" }
                        },
                        [
                          _c(
                            "b-row",
                            {
                              attrs: { "no-gutters": "", "align-v": "center" }
                            },
                            [
                              _c("b-col", { staticClass: "pl-3" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("compFilt")(
                                        _vm.compared
                                          ? Math.abs(_vm.compareSum)
                                          : 0,
                                        _vm.totalComp !== 0 ? _vm.totalComp : 1
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c(
                                "b-col",
                                { staticClass: "small" },
                                [
                                  _vm.compared
                                    ? _c("PercentDisplay", {
                                        staticClass: "pl-3",
                                        attrs: {
                                          type: "difference",
                                          "new-amount": _vm.income
                                            ? Math.round(
                                                (_vm.accounts.total /
                                                  _vm.income) *
                                                  100,
                                                2
                                              )
                                            : 100,
                                          "old-amount": _vm.calcCompPerc(
                                            _vm.totalComp !== 0
                                              ? _vm.totalComp
                                              : 1
                                          ),
                                          switchComp: _vm.switchComp
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-inline",
                  staticStyle: {
                    position: "absolute",
                    right: "1.5em",
                    transform: "translateY(-1.5em)"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text--sbs-red",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.openModal = true
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "graph-up" } })],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm.accounts
            ? _c(
                "b-collapse",
                {
                  model: {
                    value: _vm.collapse,
                    callback: function($$v) {
                      _vm.collapse = $$v
                    },
                    expression: "collapse"
                  }
                },
                [
                  _vm._l(_vm.accounts.transactions, function(t, idx) {
                    return _c(
                      "b-card-body",
                      { key: idx, staticClass: "p-0" },
                      [
                        _vm.collapse
                          ? _c("AccountDetail", {
                              attrs: {
                                transactions: t,
                                compared: _vm.compareTo
                                  ? _vm.compareTo[idx]
                                  : null,
                                isComparing: _vm.isComparing,
                                income: _vm.income,
                                totalComp: _vm.totalComp,
                                switchComp: _vm.switchComp,
                                accounttype: _vm.name,
                                filters: _vm.filters
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _c(
                    "b-card-footer",
                    [
                      _c(
                        "b-row",
                        { staticClass: "pr-0 pr-md-5" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "text-md-right",
                              attrs: { cols: "12", md: "6" }
                            },
                            [
                              _c("strong", [
                                _vm._v("Total " + _vm._s(_vm.name) + ":")
                              ])
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right",
                              class: _vm.isComparing ? "pr-md-0" : "",
                              attrs: {
                                offset: "0",
                                "offset-md": !_vm.isComparing ? 3 : 0,
                                cols: "12",
                                md: "3"
                              }
                            },
                            [
                              _c(
                                "b-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("b-col", { staticClass: "pl-1" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm._f("total")(_vm.accounts)
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "b-col",
                                    { staticClass: "small text-center" },
                                    [
                                      _vm.hasIncome
                                        ? _c("PercentDisplay", {
                                            staticClass: "pl-3",
                                            attrs: {
                                              type: "income",
                                              "new-amount": _vm.accounts.total,
                                              "old-amount": _vm.income
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isComparing
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "12", md: "3" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c("b-col", { staticClass: "pl-3" }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("compFilt")(
                                                _vm.compared
                                                  ? Math.abs(_vm.compareSum)
                                                  : 0,
                                                _vm.totalComp !== 0
                                                  ? _vm.totalComp
                                                  : 1
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "b-col",
                                        { staticClass: "small" },
                                        [
                                          _vm.compared
                                            ? _c("PercentDisplay", {
                                                staticClass: "pl-3",
                                                attrs: {
                                                  type: "difference",
                                                  "new-amount": _vm.income
                                                    ? Math.round(
                                                        Math.round(
                                                          Math.abs(
                                                            _vm.accounts.total
                                                          )
                                                        ) / _vm.income
                                                      ) / 1000
                                                    : 100,
                                                  "old-amount": _vm.calcCompPerc(
                                                    _vm.totalComp !== 0
                                                      ? _vm.totalComp
                                                      : 1
                                                  ),
                                                  switch: _vm.switchComp
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                "hide-footer": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                title: _vm.name
              },
              model: {
                value: _vm.openModal,
                callback: function($$v) {
                  _vm.openModal = $$v
                },
                expression: "openModal"
              }
            },
            [
              _c("LineChart", {
                attrs: { data: _vm.chartdata, options: _vm.options }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }