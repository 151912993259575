var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c(
            "b-row",
            { staticClass: "pr-0 pr-md-5" },
            [
              _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                _c("strong", [_vm._v(_vm._s(_vm.label))])
              ]),
              _c(
                "b-col",
                {
                  staticClass: "text-right",
                  class: _vm.isComparing ? "pr-md-0" : "",
                  attrs: {
                    cols: "12",
                    md: "3",
                    offset: "0",
                    "offset-md": !_vm.isComparing ? 3 : 0
                  }
                },
                [
                  _c(
                    "b-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "pl-1" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("isNegative")(
                                _vm._f("currency")(_vm.total)
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "small text-center" },
                        [
                          _c("PercentDisplay", {
                            staticClass: "pl-3",
                            attrs: {
                              "new-amount": Math.abs(_vm.total),
                              "old-amount": _vm.income
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isComparing
                ? _c(
                    "b-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", md: "3" }
                    },
                    [
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("b-col", { staticClass: "pl-3" }, [
                            _vm.compared !== null
                              ? _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("isNegative")(
                                        _vm._f("compFilt")(
                                          _vm.compared,
                                          _vm.totalComp !== 0
                                            ? _vm.totalComp
                                            : 1
                                        )
                                      )
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "small" },
                            [
                              _vm.compared !== null
                                ? _c("PercentDisplay", {
                                    staticClass: "pl-3",
                                    attrs: {
                                      type: "difference",
                                      switchComp: _vm.switchComp,
                                      "new-amount": _vm.income
                                        ? Math.round(
                                            (Math.round(Math.abs(_vm.total)) /
                                              _vm.income) *
                                              1000
                                          ) / 10
                                        : 0,
                                      "old-amount": _vm.calcCompPerc(
                                        Math.abs(_vm.compared),
                                        _vm.totalComp !== 0 ? _vm.totalComp : 1
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }